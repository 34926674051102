"use client"
import React, { useEffect, useState } from "react"
import PopupAction from "../shared/popup-action/popup-action"
import { cn } from "@/lib/utils"
import ButtonAction from "../shared/button-action/button-action"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { urlAsset } from "@/helper/utils"
import { Image } from "../shared/image/image"
import UseQueryParams from "@/hooks/use-query-params"

const PopupStopBeforeContinue = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const { handleMultipleManipulateQuery } = UseQueryParams()
  const { push } = useRouter()

  useEffect(() => {
    setIsPopupOpen(
      searchParams.has("holdstep") &&
        searchParams.get("holdstep") === "brandpref"
    )
  }, [searchParams])

  const handleCloseMenu = () => {
    let links = { pathname: pathname, query: null }
    const newQuery = {
      holdstep: false,
    }
    links.query = newQuery
    return links
  }

  return (
    <PopupAction
      zIndex="z-[21]"
      maxWidth={"max-w-[364px]"}
      padding="p-0"
      className={""}
      isShowing={isPopupOpen}
      href={handleCloseMenu()}
      onClickBtnX={() => {
        console.log("clicked")
      }}
      showCloseBtn={true}
    >
      <div
        className={cn(
          `relative z-[1] p-[30px] w-full overflow-hidden h-full rounded-[15px]`
        )}
      >
        <div className="w-[100%] h-[100%] aspect-[304/177] rounded-[15px] mb-[20px]">
          <Image
            className={"aspect-[304/177]"}
            src={urlAsset() + "/titanium/brand-pref.png"}
            alt="onboard"
          />
        </div>
        <div className="text-[20px] leading-[28px] font-[900] text-center text-text-2 px-[30px] mb-[10px]">
          Hold Up!
        </div>
        <div className="text-[16px] leading-[24px] text-center text-text-2 max-w-[304px] mx-auto mb-[20px] px-[20px]">
          To continue playing the next engagement, {"you'll"} need to fill your
          Brand Preference beforehand.
        </div>
        <ButtonAction
          onClick={() => {
            localStorage.removeItem("brand-preference")
            setTimeout(() => {
              handleMultipleManipulateQuery(false,
                ["brandPreferences", true],
                ["source", "titanium"],
                ["holdstep", false],
              )
            }, [100])
          }}
          intent={"primary"}
        >
          Fill My Brand Preference
        </ButtonAction>
      </div>
    </PopupAction>
  )
}

export default PopupStopBeforeContinue
