"use client"
import React, { useCallback, useEffect, useRef, useState } from "react"
import PopupAction from "../shared/popup-action/popup-action"
import { useSearchParams } from "next/navigation"
import { cn } from "@/lib/utils"
import { Description } from "../shared/description/description"
import ButtonAction from "../shared/button-action/button-action"
import {
  useAnimation,
  useMotionValueEvent,
  motion,
  useScroll,
  AnimatePresence,
} from "framer-motion"
import { useViewportSize } from "@mantine/hooks"

const PopupBackToAllaccess = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [timeStarted, setTimeStarted] = useState(false)
  const [isRunning, setIsRunning] = useState(false)
  const [time, setTime] = useState(30)
  const searchParams = useSearchParams()
  const init = useRef(true)
  const controls = useAnimation()
  let { scrollY } = useScroll()
  let { height } = useViewportSize()

  useEffect(() => {
    if (
      searchParams.has("utm_source") &&
      searchParams.get("utm_source") === "allaccess-iframe" &&
      init.current
    ) {
      localStorage.removeItem("soft_age_gate")
      init.current = false
    }

    if (
      searchParams.has("utm_source") &&
      searchParams.get("utm_source") === "allaccess-iframe" &&
      !timeStarted
    ) {
      setTimeout(() => setIsRunning(true), 1000)
      setTimeStarted(true)
      const timer = setTimeout(() => {
        setTimeStarted(false)
        setIsPopupOpen(true)
      }, 31000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [])

  const timeOutCallback = useCallback(() => {
    setTime((prev) => prev - 1)
    // eslint-disable-next-line
  }, [])

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (latest <= 1) {
      controls.start({
        y: 0,
      })
    } else if (
      latest < scrollY.getPrevious() ||
      height + latest >= document.body.offsetHeight
    ) {
      controls.start({
        y: 0,
        transition: {
          duration: 0.3,
          ease: "easeOut",
        },
      })
    } else {
      controls.start({
        y: -64,
        transition: {
          duration: 0.2,
          ease: "easeOut",
        },
      })
    }
  })

  useEffect(() => {
    let timer
    if (isRunning) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.6,
          ease: "easeIn",
        },
      })
      // setTimeout(() => {
      if (time > 0) {
        timer = setTimeout(timeOutCallback, 1000)
      } else {
        if (time === 0) {
          clearTimeout(timer)
        }
      }
      // }, 2000)
    }
  }, [time, isRunning])

  return (
    <>
      <AnimatePresence>
        {timeStarted ? (
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            animate={controls}
            className={cn(
              "fixed top-[66px] lg:top-[82px] left-[10px] bg-cta-1 p-[10px] px-[20px] rounded-[8px] z-[20] text-cta-2"
            )}
          >
            {time}
            {time > 1 ? " seconds" : " second"} until you can go back to
            allaccess
          </motion.div>
        ) : null}
      </AnimatePresence>
      <PopupAction
        zIndex="z-20"
        maxWidth={"max-w-[450px]"}
        padding="p-0"
        className={""}
        isShowing={isPopupOpen}
        href={"#"}
        onClickBtnX={() => {
          setIsPopupOpen(false)
        }}
        showCloseBtn={false}
      >
        <div
          className={cn(
            `relative z-[1] p-[20px] w-full overflow-hidden h-full rounded-[15px]`
          )}
        >
          <div className="text-[20px] lg:text-[24px] leading-[28px] lg:leading-[36px] font-bold font-font-family-3 mb-[10px] text-text-2">
            Thank You For Your Visit
          </div>
          <Description
            className="text-[14px] lg:text-[16px] leading-[20px] lg:leading-[24px] lg:text-base font-medium font-font-family-6 text-text-2 mb-[20px] text-justify"
            description={
              "<p>Thank you for spending your time with us! We truly appreciate your interaction. You can return to AllAccess whenever you're ready to continue your game. Enjoy!</p>"
            }
          />
          <div className="flex flex-col lg:flex-row items-center justify-between gap-[10px] max-w-[355px] lg:h-[50px] mx-auto mt-[20px]">
            <a
              href={`${process.env.NEXT_PUBLIC_ALLACCESS_URL}/o2o/iframe?action=visited_page`}
              // target="_parent"
              // referrerPolicy="no-referrer"
              className="inline-block w-[100%] grow-[1]"
            >
              <ButtonAction
                className="py-[15px] px-[20px] leading-[20px] font-font-family-7 w-[100%]"
                intent={"primary"}
                // onClick={() => {
                //   requestStorageAccess()
                // }}
                // disabled={isLoadingAccess}
              >
                Back To Allaccess
              </ButtonAction>
            </a>
            {/* <ButtonAction
                      className="py-[15px] px-[20px] leading-[20px] font-font-family-7 grow-[1] lg:max-w-[172px]"
                      intent={!isLoadingAccess ? "primary" : "primary_disable"}
                      onClick={() => {
                        requestStorageAccess()
                      }}
                      disabled={isLoadingAccess}
                    >
                      Allow Cookie
                    </ButtonAction> */}
          </div>
        </div>
      </PopupAction>
    </>
  )
}

export default PopupBackToAllaccess
