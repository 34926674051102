"use client"
import { Image } from "@/components/shared/image/image"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { cn } from "@/lib/utils"
import { usePathname } from "next/navigation"
import React from "react"

const GHW = () => {
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const hideNav =
    (pathname.includes("/cc-bridging") ||
      pathname.includes("/why-not") ||
      pathname.includes("/maha-karaoke")) &&
    pathname !== "/br/why-not/inspiration/music/iframe/why-not" &&
    pathname !== "/why-not/br"
  return (
    <>
      {!hideNav && (
        <div
          className={cn(
            "flex bg-white items-center justify-between fixed bottom-0 h-[56px] w-full z-[20]"
            //  pathname.includes('joget-ambil-asiknya/br') ? 'hidden' : ''
          )}
        >
          <Image
            src={`/assets/ghw/${isMobile ? "Mobile" : "Desktop"}-GHW.png`}
            alt="GHW"
            objectFit={"contain"}
          />
        </div>
      )}
    </>
  )
}

export default GHW
